<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="event-id"
              v-model="filters.eventId"
              name="eventId"
              :label="$t('Id do evento')"
              type="text"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="event-name"
              v-model="filters.name"
              name="name"
              :label="$t('Nome')"
              type="text"
            />
          </b-col>
          <b-col md="4">
            <e-store-combo
              id="event-store_id"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="event-table"
        ref="eventTable"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('EVENT.NAME'), 'male')"
        :fields="fields"
        :items="events"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(delivery)="row">
          <e-status-badge
            :status="row.item.delivery"
            type="yesNo"
          />
        </template>
        <template #cell(consigned)="row">
          <e-status-badge
            :status="row.item.consigned"
            type="yesNo"
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.status === 'Active'"
            :show-update="row.item.status !== 'Finished' && row.item.status !== 'Canceled'"
            :show-delete="row.item.status !== 'Finished' && row.item.status !== 'Canceled'"
            :show-activate="row.item.status !== 'Finished' && row.item.status !== 'Canceled'"
            :show-deactivate="row.item.status !== 'Finished' && row.item.status !== 'Canceled'"
            :buttons="[
              {
                icon: 'search',
                variant: 'primary',
                title: $t('Visualizar evento'),
                event: 'event-read-only',
              },
              {
                icon: 'check',
                variant: 'primary',
                title: $t('Finalizar evento'),
                event: 'finish',
                hidden: row.item.status === 'Finished' || row.item.status === 'Canceled',
              },
              {
                icon: 'x-circle',
                variant: 'danger',
                title: $t('Cancelar evento'),
                event: 'cancel',
                hidden: row.item.status === 'Finished' || row.item.status === 'Canceled',
              },
            ].filter((button) => !button.hidden)
            "
            @event-read-only="showEventReadOnly(row)"
            @update="editEvent(row)"
            @delete="deleteEventLocal(row)"
            @activate="activateEvent(row)"
            @deactivate="deactivateEvent(row)"
            @finish="finishEvent(row)"
            @cancel="cancelEvent(row)"
          />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="event-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <fab
      v-if="$can('Create', 'Event')"
      :main-tooltip="$t('Adicionar Evento')"
      @click="onCreateEvent"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EGridActions,
  EStatusBadge,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { statusTypes } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EStatusBadge,
    EStoreCombo,
  },

  mixins: [statusTypes],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapGetters('common/priceTables', ['getComboEvents']),
    ...mapState('pages/sale/event', ['events', 'sorting', 'paging', 'filters']),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          formatter: val => this.$options.filters.storeName(val),
        },
        {
          label: this.$t('Status'),
          key: 'status',
          thStyle: { width: '100px' },
          tdClass: 'text-center',
          formatter: val => this.$t(val),
        },
        {
          label: this.$t('Tabela de preços'),
          key: 'priceTable',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '200px' },
          formatter: val => this.$options.filters.idName(val),
        },
        {
          label: this.$t('Data de início'),
          key: 'startDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px', minWidth: '100px' },
          sortable: true,
          formatter: val => this.$options.filters.date(val, '-'),
        },
        {
          label: this.$t('Data fim'),
          key: 'endDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px', minWidth: '100px' },
          sortable: true,
          formatter: val => this.$options.filters.date(val, '-'),
        },
      ]
    },
  },

  mounted() {
    this.fetchEvents()
    this.getData()
  },

  methods: {
    ...mapActions('pages/sale/event', [
      'fetchEvents',
      'deleteEvent',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),
    ...mapActions('pages/sale/event/eventMaintain', ['cleanState']),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchEvents()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateEvent() {
      this.cleanState()
      this.$router.push({ name: 'event-add' })
    },

    async showEventReadOnly(row) {
      const { id } = row.item
      this.$router.push({ name: 'event-read-only', params: { id } })
    },

    editEvent(row) {
      const { id } = row.item
      this.$router.push({ name: 'event-maintain', params: { id } })
    },
    async deleteEventLocal(row) {
      if (await this.confirm({ text: this.$t('Tem certeza que deseja excluir este evento?') })) {
        try {
          this.fetching = true
          await this.deleteEvent(row.item.id)
          this.showSuccess({ message: this.$t('Evento excluído com sucesso') })
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      }
    },
    async activateEvent(row) {
      try {
        this.fetching = true
        await this.$http.put(`/api/sales/events/${row.item.id}/activate`)
        this.showSuccess({ message: this.$t('Evento ativado com sucesso') })
        this.getData()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
    async deactivateEvent(row) {
      if (await this.confirm({ text: this.$t('Tem certeza que deseja desativar este evento?') })) {
        try {
          this.fetching = true
          await this.$http.put(`/api/sales/events/${row.item.id}/deactivate`)
          this.showSuccess({ message: this.$t('Evento desativado com sucesso') })
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      }
    },
    async cancelEvent(row) {
      if (await this.confirm({ text: this.$t('Tem certeza que deseja cancelar este evento?') })) {
        try {
          this.fetching = true
          await this.$http.put(`/api/sales/events/${row.item.id}/cancel`)
          this.showSuccess({ message: this.$t('Evento cancelado com sucesso') })
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      }
    },
    async finishEvent(row) {
      if (await this.confirm({ text: this.$t('Tem certeza que deseja finalizar este evento?') })) {
        try {
          this.fetching = true
          await this.$http.put(`/api/sales/events/${row.item.id}/finish`)
          this.showSuccess({ message: this.$t('Evento finalizado com sucesso') })
          this.getData()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      }
    },
  },
}
</script>

<style></style>
